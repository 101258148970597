@tailwind base;
@tailwind components;
@tailwind utilities;

$mainColor: #dc3545;
$background: 203 57.2% 3.76%;
$foreground: 203 8.8% 97.35%;
/* ------ Custom Scroll bar -------- */

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #292524;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #57534e;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #57534e;
}

/* --------------------------------- */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modal-token-item {
  display: grid;
  cursor: pointer;
  grid-template-columns: 40px minmax(auto, 1fr);
  align-items: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

body {
  background-color: hsl($background);
  color: hsl($foreground);
}

.main-container {
  background-image: url("../images/background.jpg");
  background-size: cover;
}
.connect-wallet {
  background-image: linear-gradient(
    280deg,
    rgb(183, 118, 255) 0%,
    rgb(93, 216, 190) 100%
  );
  &:hover {
    transform: scale(1.025);
  }
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
